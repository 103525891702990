const productPageHeader = document.querySelector(".product-page-header, .landingpage-hero--wrapper, .news-and-contact-header");
const navbar = document.querySelector(".navbar-ti");
const options = {
    rootMargin: "0px 0px 1000% 0px",
    threshold: 1.0,
};

const observer = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
        navbar.classList.toggle("navbar-ti--no-shadow", true);
    } else {
        navbar.classList.toggle("navbar-ti--no-shadow", false);
    }
}, options);

observer.observe(productPageHeader);